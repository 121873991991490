* {
  font-weight: 500;
  font-family: 'Archivo', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  /*font-size: 16px !important;*/
  font-family: 'Archivo', sans-serif !important;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.isProcessingAnimationIcon {
  animation: spin 1.2s linear 0s infinite;
}

/* Helpers */

.-alignCenter {
  text-align: center !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.Toastify__fade-enter {
  animation-name: fadeIn;
}

.Toastify__fade-exit {
  animation-name: fadeOut;
}

.Toastify__toast {
  align-items: center;
  padding: 1rem;
}

.Toastify__toast.Toastify__toast--success {
  background-color: #2ea077;
}

.Toastify__toast.Toastify__toast--error {
  background-color: #eb5757;
}

input.MuiInputBase-input {
  font-size: 1rem;
}

p {
  margin: 0 0 1rem;
}
