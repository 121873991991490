@keyframes ArrowsFadeIn {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.el1 {
  animation: ArrowsFadeIn 1s linear 0.6s infinite;
}

.el2 {
  animation: ArrowsFadeIn 1s linear 0.3s infinite;
}

.el3 {
  animation: ArrowsFadeIn 1s linear 0s infinite;
}
